<template>
    <div class="page">
        <div class="operate" v-if="signShow==false">
            <div class="operate-text">你还未开通商品推广功能暂无法使用此功能</div>
            <a-button type="primary" class="plus" @click="setDredge">开通商品推广功能</a-button>
        </div>
        <div v-else class="agreement">
            <div class="agreement_cont">
                <div class="agreement_header">
                    <div>商品分销功能说明</div>
                    <div @click="clean">
                        <a-icon type="close" />
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <editor v-model="form.agreement" :type="true"/>
                </div>

                <div class="agreement_operate" >
                    <a-button @click="clean">取消</a-button>
                    <a-button type="primary" style="margin-left: 20px;" @click="setAgree">同意</a-button>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import { dateTimeStr } from '@/common/date.js'
import {agreement,agree} from '@/api/system/config'
import Editor from "@/components/Editor/Editor";
import { updateMenu } from '@/api/system/menu'
export default {
    components: { Editor },
    data() {
        return {
            signShow:false,
            form:{
                agreement:''
            }

        }
    },
    methods: {
        //开通
        setDredge(){
            this.signShow = true
            this.getAgreement()
        },

        //获取协议
        getAgreement(){

            let configKey=process.env.VUE_APP_CONFIG_KEY
            agreement(configKey).then(res=>{
                this.form.agreement = res.data
               
                this.form.agreement = res.data
            })
        },

        // 取消
        clean(){
            this.signShow = false
        },

        // 同意
        async setAgree(){

            let params = {
                "id": process.env.VUE_APP_AGREE_ID,
                "configGroup": "merchant.promotion",
                "configName": "是否同意分销协议",
                "configKey": process.env.VUE_APP_CONFIG_KEY_AGREE,
                "configValue": "true",
                "configType": "2",
                "configSystem": "Y",
                "configLocale": "N",
                "createTime": dateTimeStr('y-m-d h:i:s'),
                "remark": ""
            }
            let res = await agree(params)
            if(res.code == '20001'){
                this.$message.success('开通推广功能已成功！')

                 let menuList1 = {
                    "id": "3310",
                    "menuName": "数据报表",
                    "parentId": "3306",
                    "orderNum": 0,
                    "path": "dataReport",
                    "component": "promotion/dataReport/index",
                    "query": "",
                    "isFrame": 0,
                    "isCache": 1,
                    "menuType": "C",
                    "visible": 1,
                    "perms": "",
                    "icon": "build",
                    "status": 1,
                    "createTime": "2025-03-27 08:41:34",
                    "remark": "",
                    "children": [],
                    "diyDate": ""
                }
                let menuList2 = {
                    "id": "3308",
                    "menuName": "推广基础设置",
                    "parentId": "3306",
                    "orderNum": 1,
                    "path": "basicSettings",
                    "component": "promotion/basicSettings/index",
                    "query": "",
                    "isFrame": 0,
                    "isCache": 1,
                    "menuType": "C",
                    "visible":  1,
                    "perms": "user:promotion:info",
                    "icon": "dateRange",
                    "status": 1,
                    "createTime": "2025-03-26 19:38:03",
                    "remark": "",
                    "children": [],
                    "diyDate": ""
                }
                let menuList3 = {
                    "id": "3311",
                    "menuName": "推广专员管理",
                    "parentId": "3306",
                    "orderNum": 2,
                    "path": "promotionSpecialist",
                    "component": "promotion/promotionSpecialist/index",
                    "query": "",
                    "isFrame": 0,
                    "isCache": 1,
                    "menuType": "C",
                    "visible": 1,
                    "perms": "",
                    "icon": "peoples",
                    "status": 1,
                    "createTime": "2025-03-27 08:43:04",
                    "remark": "",
                    "children": [],
                    "diyDate": ""
                }
                let menuList4 = {
                    "id": "3312",
                    "menuName": "佣金管理",
                    "parentId": "3306",
                    "orderNum": 3,
                    "path": "commission",
                    "component": "promotion/commission/index",
                    "query": "",
                    "isFrame": 0,
                    "isCache": 1,
                    "menuType": "C",
                    "visible": 1,
                    "perms": "",
                    "icon": "pay-circle",
                    "status": 1,
                    "createTime": "2025-03-27 08:44:10",
                    "remark": "",
                    "children": [],
                    "diyDate": ""
                }
                let menuList5 = {
                    "id": "3313",
                    "menuName": "开通推广功能",
                    "parentId": "3306",
                    "orderNum": 1,
                    "path": "removeObstaclesFrom",
                    "component": "promotion/removeObstaclesFrom/index",
                    "query": "",
                    "isFrame": 0,
                    "isCache": 1,
                    "menuType": "C",
                    "visible": 0,
                    "perms": "",
                    "icon": "bxAnalyse",
                    "status": 0,
                    "createTime": "2025-03-29 08:48:46",
                    "remark": "",
                    "children": [],
                    "diyDate": ""
                }
                await updateMenu(menuList1)
                await updateMenu(menuList2)
                await updateMenu(menuList3)
                await updateMenu(menuList4)
                await updateMenu(menuList5)
                this.$router.push({ path: '/promotion/basicSettings' })
                setTimeout(()=>{
                    window.location.reload()
                },100)
            }

           
        }

    }
}
</script>
<style scoped>
.page{
    width: 100%;
    height: 90vh;
    background: #fff;
    padding: 20px 40px;
}

.operate,.agreement{
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.operate-text{
    font-size: 17px;
    color: #000;
}

.plus{
    width: 200px;
    margin-top: 20px;
}

.agreement_cont{
    width: 50%;
    border: 1px solid #333;
    padding: 20px;
    position: relative;
    height: 70vh;


}
.agreement_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 15px;
}

.agreement_operate{
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.editorClass{
    margin-top: 10px;
    height: 100px;
}

.editorClass{
    height: 100px !important;
}
</style>